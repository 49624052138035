<template>
  <div class="container">
    <My-Breadcrumb :breadcrumbs="breadcrumbs" />
    <div class="mainBox">
      <!-- 控制区域 -->
      <div class="control_box">
        <Search-bar :timeRange="timeRange" @search="search" @reset="search" />
        <div class="control_box_bottom">
          <a-button type="primary" icon="printer" @click="downloadExcel" :disabled="tableData.length == 0 || loading">导出</a-button>
          <div class="info-tips">【用户消费报告】为实收统计，不包含已报餐未就餐和支付后退款的统计，如需查看消费预收统计，请查看【用户账户报告】</div>
        </div>
      </div>
      <!-- 内容区域 -->
      <div class="show_box">
        <a-table :loading="loading" :columns="columns" :data-source="tableData" bordered :pagination="pagination"
          @change="handleTableChange" :scroll="{ x: 2000, y: 500 }"></a-table>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getExpenseReport, exportExpenseReport } from "@/request/api";

//引入封装的组件
import SearchBar from "@/components/common/SearchBar.vue";
import MyBreadcrumb from "@/components/common/MyBreadcrumb.vue";
//引入工具函数
import util from "@/utils/util";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { SearchBar, MyBreadcrumb },
  data () {
    return {
      breadcrumbs: [
        {
          key: 0,
          title: "经营报表",
        },
        {
          key: 1,
          title: "用户消费报告",
        },
      ],
      timeRange: {
        show: true,
        placeholderStart: "开始时间(默认上月)",
        placeholderEnd: "结束时间(默认上月)",
        rangeLimit: true
      },
      queryParam: {
        page: 1,
        begin_date: "",
        end_date: "",
      },
      tableData: [], //从接口请求回来并在js部分整理好的表格数据
      tableDataLength: 0,
      loading: false,
      columns: [], // 表格的列配置
      timeData: [],
      pagination: {
        total: 0,
        pageSize: 11, //接口返回10条数据  加上接口返回之后前端处理添加的一条合计数据总11条
        current: 1,
      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //列表下载
    downloadExcel () {
      this.$confirm({
        title: "确定导出表格?",
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          this.export2Excel();
        },
        onCancel: () => { },
      });
    },
    //数据写入excel
    export2Excel () {
      exportExpenseReport(this.queryParam).then((res) => {
        util.acceptFileStream(res.data, "用户消费报告.xls");
      });
    },
    // 刷新
    search (val) {
      this.queryParam = {
        page: 1,
        begin_date: val.timeRange.startTime,
        end_date: val.timeRange.endTime,
      };
      this.pagination.current = 1;
      this.getTableData();
    },
    //获取表格数据
    getTableData () {
      this.loading = true;
      //获取带分页的数据用于渲染
      getExpenseReport(this.queryParam).then((res) => {
        let resultData = res.data,
          mealsType = [];
        for (let key in resultData.meal_title) {
          mealsType.push(resultData.meal_title[key]);
        }
        //处理页码
        const pagination = { ...this.pagination };
        pagination.total = resultData.total + Math.ceil(resultData.total / 11);
        this.pagination = pagination;

        if (resultData.list.length == 0) {
          this.loading = false;
          this.tableData = [];
          return;
        }
        let formatData = resultData.list,
          key = 1;
        //处理接口table数据
        for (let i = 0; i < formatData.length; i++) {
          for (let j = 0; j < formatData[i].meal.length; j++) {
            // 每个时段下的系统核销数量
            formatData[i]["sysPayCount" + formatData[i].meal[j].id] =
              formatData[i].meal[j].check[0].count;
            // 每个时段下的系统核销金额 
            formatData[i]["sysPayMoney" + formatData[i].meal[j].id] =
              formatData[i].meal[j].check[0].money;
            // 每个时段下的食堂核销数量
            formatData[i]["instPayCount" + formatData[i].meal[j].id] =
              formatData[i].meal[j].check[1].count;
            // 每个时段下的食堂核销金额
            formatData[i]["instPayMoney" + formatData[i].meal[j].id] =
              formatData[i].meal[j].check[1].money;
            formatData[i].meal_small_count =
              formatData[i].total_count;
            formatData[i].meal_small_money =
              formatData[i].total_money;
          }
        }
        let totalObj = {
          office_title: "合计",
          post_title: " ",
          work_id: " ",
          realname: " ",
          meal_small_count: resultData.meal_total_count,
          meal_small_money: resultData.meal_total_money,
          expense_count: resultData.expense_total_count,
          expense_money: resultData.expense_total_money,
          store_count: resultData.store_total_count,
          store_money: resultData.store_total_money,
        };
        // 表格最后一行的合计数据：系统核销
        for (let key in resultData.meal_total_1) {
          totalObj["sysPayCount" + key] = resultData.meal_total_1[key].count;
          totalObj["sysPayMoney" + key] = resultData.meal_total_1[key].money;
        }
        // 表格最后一行的合计数据：食堂核销
        for (let key in resultData.meal_total_2) {
          totalObj["instPayCount" + key] = resultData.meal_total_2[key].count;
          totalObj["instPayMoney" + key] = resultData.meal_total_2[key].money;
        }
        formatData.push(totalObj);
        formatData.map((item) => {
          item.key = key++;
          return item;
        });
        this.loading = false;
        this.tableData = formatData;
        this.tableDataLength = formatData.length;

        //配置表格列头
        let children = [],
          lastChild = {
            title: "小计",
            children: [
              {
                title: '所有验证',
                align: 'center',
                children: [
                  {
                    title: "用餐次数",
                    align:'center',
                    dataIndex: "meal_small_count",
                    ellipsis:true,
                    width: 100
                  },
                  {
                    title: "金额",
                    align:'center',
                    dataIndex: "meal_small_money",
                    ellipsis:true,
                    width: 100
                  },
                ],
              }
            ]
          };

        for (let i = 0; i < mealsType.length; i++) {
          children.push({
            title: mealsType[i].title,
            dataIndex: mealsType[i].id,
            key: mealsType[i].id,
            ellipsis:true,
            // width:200,
            customHeaderCell: (record, index) => {
              return {
                attrs:{
                  title: mealsType[i].title,
                },
              }
            },
            children: [
              {
                title: '系统验证',
                align: 'center',
                children: [
                  {
                    title: '用餐次数',
                    align:'center',
                    dataIndex: "sysPayCount" + mealsType[i].id,
                  },
                  {
                    title: "金额",
                    align:'center',
                    dataIndex: "sysPayMoney" + mealsType[i].id,
                  },
                ]
              },
              {
                title: '食堂验证',
                align: 'center',
                children: [
                  {
                    title: '用餐次数',
                    align:'center',
                    dataIndex: "instPayCount" + mealsType[i].id,
                  },
                  {
                    title: "金额",
                    align:'center',
                    dataIndex: "instPayMoney" + mealsType[i].id,
                  },
                ]
              }
            ],
          });
        }

        children.push(lastChild);

        this.columns = [
          {
            title: "部门",
            align:'center',
            dataIndex: "office_title",
            width: 117,
            fixed: "left",
            customRender: (value, row, index) => {
              let tableLength = this.tableDataLength;
              const obj = {
                children: value,
                attrs: {},
              };
              if (row.key === tableLength) {
                obj.attrs.colSpan = 4;
              }
              return obj;
            },
          },
          {
            title: "职务",
            align:'center',
            dataIndex: "post_title",
            width: 117,
            fixed: "left",
            customRender: (value, row, index) => {
              let tableLength = this.tableDataLength;
              const obj = {
                children: value,
                attrs: {},
              };
              if (row.key === tableLength) {
                obj.attrs.colSpan = 0;
              }
              return obj;
            },
          },
          {
            title: "工号",
            align:'center',
            dataIndex: "work_id",
            width: 117,
            fixed: "left",
            customRender: (value, row, index) => {
              let tableLength = this.tableDataLength;
              const obj = {
                children: value,
                attrs: {},
              };
              if (row.key === tableLength) {
                obj.attrs.colSpan = 0;
              }
              return obj;
            },
          },
          {
            title: "姓名",
            align:'center',
            dataIndex: "realname",
            width: 117,
            fixed: "left",
            customRender: (value, row, index) => {
              let tableLength = this.tableDataLength;
              const obj = {
                children: value,
                attrs: {},
              };
              if (row.key === tableLength) {
                obj.attrs.colSpan = 0;
              }
              return obj;
            },
          },
          {
            title: "用餐",
            // width: 2000,
            children: children,
          },
          {
            title: "临时消费码",
            children: [
              {
                title: "消费次数",
                align:'center',
                dataIndex: "expense_count",
                width: 100,
                ellipsis:true
              },
              {
                title: "金额",
                align:'center',
                dataIndex: "expense_money",
                width: 100,
                ellipsis:true
              },
            ],
          },
          {
            title: "商城消费",
            children: [
              {
                title: "订单数",
                align:'center',
                dataIndex: "store_count",
                width: 100,
                ellipsis:true
              },
              {
                title: "金额",
                align:'center',
                dataIndex: "store_money",
                width: 100,
                ellipsis:true
              },
            ],
          },
        ];
      });
    },
    //分页
    handleTableChange (pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.queryParam.page = pagination.current;
      this.getTableData();
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.getTableData();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.mainBox {
  .control_box {
    background-color: white;
    padding: 20px;
    .control_box_bottom {
      margin-top: 20px;
      display: flex;
      justify-self: start;
      .info-tips{
        line-height: 32px;
        width: 100%;
        text-align: center;
      }
    }
  }
  .show_box {
    width: 100%;
    padding-top: 10px;
  }
}
</style>